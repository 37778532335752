<template>
  <div class="auth-wrapper auth-v1">
    <!-- Login v1 -->
    <div class="d-flex flex-column align-items-center">
      <div class="brand-logo m-0 d-flex flex-column align-items-center mb-1">
        <b-img
          :src="appLogoImage"
          style="width: 50px"
          alt="logo"
          class="img-fluid"
        />

        <h3>Applead</h3>
      </div>
      <div class="card d-flex" style="width: 90% !important;">
        <div class="d-flex">
          <div class="d-xl-flex d-none mr-2">
            <img src="@/assets/images/maintenance.png" />
          </div>
          <div class="d-flex flex-column justify-content-between p-2">
            <div>
              <h4>Invitation to access this site</h4>
              <p>
                Please enter your email and post your demand in <a href="https://www.facebook.com/groups/applead.io" target="_blank">AppLead facebook group</a> , if we accept your invitation we will send you the registration link to your email.
              </p>
            </div>
            <!--? form -->
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    name="invite-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Email"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <!-- submit button -->
                <div class="d-flex justify-content-between mt-1">
                  <b-button variant="outline-primary" to="/login">
                    Back To Login
                  </b-button>
                  <b-button
                    variant="primary"
                    @click="sendEmail"
                    :disabled="invalid"
                  >
                    Send Email
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>

    <!-- /Login v1 -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BImg,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import { required, regex } from "@validations";
import axios from "@axios";
import router from "@/router";
import { mapState, mapMutations } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";

import { getAccessTokenFromCookie } from "@/auth/utils";

export default {
  components: {
    // BSV
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BImg,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
  },
  data() {
    const { appLogoImage } = $themeConfig.app;
    return {
      email: "",
      appLogoImage,
      required,
      regex,
    };
  },
  computed: {
    ...mapState(["sent"]),
  },
  methods: {
    ...mapMutations(["setSent"]),
    send() {
      this.setSent(true);
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    sendEmail() {
      axios.post("/invite-email", { email: this.email }).then(({ data }) => {
        if (data.status === "success") {
          this.send();
          this.$router.push({
            path: "/success-sending",
          });
        } else {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Somthing it wrong ! Try Again"
          );
        }
      });
    },
  },
    beforeRouteEnter(to, from, next) {
    const data = getAccessTokenFromCookie();
    if (data) {
      const role = data[2].role;
      if (role === 'admin') {
        next('/admin-dash');
      } else {
        next('dashboard');
      }
    }else {
      next('/register');
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
